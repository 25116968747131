import axios from "axios";

export let ENDPOINT = 'https://dev-api.muchon.kr';
// ENDPOINT = 'http://127.0.0.1:8000';


console.log(process.env)

export const axiosClient = axios.create({
  baseURL: ENDPOINT,
  // timeout: 1000,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosClient.interceptors.request.use(
  config => {
    config.headers['Authorization'] = (localStorage.getItem('AccessPermissionToken')) ? `v1 ${localStorage.getItem('AccessPermissionToken')}` : '';
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);