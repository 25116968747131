import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        {/*<Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />*/}
        <Route path={`${APP_PREFIX_PATH}/dashboard`} component={lazy(() => import(`./dashboard`))} />
        <Route path={`${APP_PREFIX_PATH}/profile/test`} component={lazy(() => import(`./profile`))} />
        <Route path={`${APP_PREFIX_PATH}/report/data`} component={lazy(() => import(`./report_sample`))} />
        <Route path={`${APP_PREFIX_PATH}/report/add`} component={lazy(() => import(`./report/add`))} />
        <Route path={`${APP_PREFIX_PATH}/report/edit`} component={lazy(() => import(`./report/edit`))} />
        <Route path={`${APP_PREFIX_PATH}/report/list`} component={lazy(() => import(`./report/list`))} />
        <Route path={`${APP_PREFIX_PATH}/report/application`} component={lazy(() => import(`./report/application`))} />
        <Route path={`${APP_PREFIX_PATH}/report`} component={lazy(() => import(`./report`))} />
        <Route path={`${APP_PREFIX_PATH}/request-sample-list`} component={lazy(() => import(`./configuration/request-sample`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboard`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);